@media (max-width: 768px) {
  .mobile-buttons {
    flex-direction: column-reverse;
  }

  .download-table-xls-button {
    margin-left: 4%;
    background: rgb(146, 145, 172);
    border: rgb(146, 145, 172);
    height: 36px;
    color: #fff;
    border-radius: 6px;
    font-size: 16px;
  }

  /* .custom-table div div:nth-child(2) {
    width: 96% !important;
  } */

  .rs-picker {
    width: 90vw !important;
    height: auto !important;
    margin-right: 4% !important;
  }

  .from-date, .to-date {
    width: 38vw !important;
  }

  .report-select {
    width: 52vw !important;
    margin-top: 10px !important;
    margin-left: 0px !important;
  }
}


/* @media (max-width: 1024px) {
  .rs-picker {
      width: 90vw !important;
      height: auto !important;
      margin-right: 4% !important;
  }
} */

@media (min-width:768px) and (max-width:1024px) {

  .rs-picker {
      width: 90vw !important;
      height: auto !important;
      margin-right: 4% !important;
      margin-top: 4% !important;
  }
}