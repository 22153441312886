.addlead {
  padding: 4% 2% 4% 4%;
  font-family: "Nunito", sans-serif;
  height: 86%;
  overflow-y: auto;
}

.lead-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 5%;
}

.lead-title span {
  font-size: 22px;
  font-weight: 500;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}

.body {
  background-color: rgba(247, 255, 250, 1);
}

label {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 1%;
  color: rgba(3, 2, 41, 1);
}

.addleadinput,
textarea {
  border-radius: 7px;
  background: rgba(247, 247, 248, 1);
  border: 0.45px solid rgba(14, 140, 67, 1);
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2) inset;
  height: 52px;
  width: 80%;
  padding: 0px 4%;
}

select {
  border-radius: 7px;
  background: rgba(247, 247, 248, 1);
  border: 0.45px solid rgba(14, 140, 67, 1);
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2) inset;
  height: 52px;
  width: 88%;
  padding: 0px 4%;
}

textarea {
  height: 100px;
  padding: 2% 4%;
}

.lead-form {
  margin: 20px 0px;
  display: flex;
  flex-wrap: wrap;
}

.lead-form .leadformfields {
  width: 32%;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 10px 0;
}

.newlead-btn {
  width: 220px;
  height: 53.04px;
  top: 858px;
  left: 773px;
  padding: 13.02px 18.23px 13.02px 18.23px;
  gap: 5.21px;
  border-radius: 10.42px 10.42px 10.42px 10.42px;
  opacity: 0px;
  background: rgb(146, 145, 172);
  color: #ffff;
  font-size: 18.23px;
  font-weight: 600;
  line-height: 26.04px;
  letter-spacing: 0.005em;
  text-align: center;
}

.btnleadnew {
  padding-top: 2% !important;
  justify-content: center !important;
  display: flex !important;
}

th,
td {
  text-wrap: nowrap;
  vertical-align: middle;
}

.add-button {
  padding: 0px;
  height: 35px;
  margin: 4% 4% 0%;
}

.add-button img {
  width: 100% !important;
  height: 100% !important;
}

.rs-picker {
  height: 52px !important;
}

.rs-input-group.rs-input-group-inside {
  width: 88% !important;
  height: 100%;
  border-radius: 7px;
  background: rgba(247, 247, 248, 1);
  border: 0.45px solid rgba(14, 140, 67, 1);
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2) inset;
}

.rs-input {
  border-radius: 7px;
  background: rgba(247, 247, 248, 1);
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2) inset;
}

.rs-picker-popup .rs-calendar {
  min-width: 334px !important;
}

.rs-calendar-month-dropdown-list {
  min-width: 250px !important;
}

.error {
  color: red;
  margin-top: 5px;
}

.order-info {
  height: 32px;
  border: 0.5px solid;
  display: flex;
  align-items: center;
}

.suggestions {
  border: 1px solid #ddd;
  max-height: 150px;
  overflow-y: auto;
  width: 85vw;
}

.suggestion-item {
  padding: 10px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}

.order-history-tracker {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0px 20px;
}

.order-step {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.order-step .status-icon i {
  color: green;
  /* Change the color for completed status */
}

.status-icon {
  font-size: 38px;
  margin-right: 15px;
}

.order-information {
  display: flex;
  flex-direction: column;
}

.order-info .status {
  font-weight: bold;
  font-size: 16px;
}

.order-info .date {
  color: gray;
  font-size: 14px;
}

.filter, .filter-date {
  cursor: pointer;
  max-width: 15%;
  display: flex;
  align-items: center;
}

.filter img {
  width: 28px;
  margin-left: 10px;
}

.filter-date img {
  width: 34px;
  margin-left: 10px;
}

.mobile-search {
  justify-content: flex-start;
}

.suggestion-item.highlighted {
  background-color: #e0e0e0;
}

.rs-pagination-group {
  align-items: stretch !important;
}

.priority td {
  color: red;
}

.table-responsive {
  height: 56vh;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {

  .addleadinput,
  select,
  textarea {
    width: 100% !important;
  }

  .mobile-row {
    flex-direction: column;
    margin-left: 1% !important;
    width: 100%;
  }

  .table-responsive {
    height: 80vh;
  }

  .from-date .rs-picker-popup-date, .rs-calendar, .rs-stack, .rs-stack-item {
    min-width: 240px !important;
    width: 240px !important;
  }

  .mobile-row div:nth-child(2) {
    width: 95% !important;
  }

  .mobile-row div:first-child {
    width: 95% !important;
  }

  .mobile-row div:first-child .from-date {
    width: 38% !important;
    margin-right: 2% !important;
  }

  .mobile-row div:first-child .rs-btn {
    margin-left: 0% !important;
    margin-bottom: 10px;
  }

  .mobile-row div:first-child .to-date {
    width: 38% !important;
  }

  .mobile-row .rs-picker-date {
    margin-bottom: 12px !important;
  }

  /* .rs-pagination-group-limit, */
  .rs-pagination-group-skip {
    display: none !important;
    ;
  }

  .rs-pagination-group-limit {
    width: 90px;
  }

  .rs-pagination-group {
    align-items: center !important;
  }

  .rs-pagination-group-total {
    text-wrap: nowrap;
  }

  .rs-pagination-md {
    display: flex !important;
  }

  .lead-form {
    width: 100% !important;
  }

  .leadformfields {
    width: 100% !important;
  }

  .rs-modal-body {
    margin-left: 0px !important;
  }

  .filter {
    max-width: 15%;
    display: flex;
    align-items: center;
  }

  .filter img {
    width: 28px;
    margin-left: 10px;
  }

  .search-field {
    width: 82% !important;
  }

  .mobile-search {
    justify-content: space-between;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lead-form {
    display: grid !important;
  }

  .lead-form div {
    width: 100% !important;
  }

  .addleadinput,
  select,
  textarea {
    width: 100% !important;
  }
}