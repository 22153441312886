body {
  font-family: "Nunito", sans-serif;
}

.sidebar {
  font-family: "Nunito", sans-serif;
}

.title {
  padding: 12px 25px !important;
  letter-spacing: 0.035em;
  pointer-events: none;
  cursor: default;
  font-size: 14px;
  color: #636363;
}

.content {
  color: #6c7780;
  display: block;
  padding: 15px 25px;
  margin: 3px 0;
  font-size: 15px;
  cursor: pointer;
}

a:active,
a:focus,
a:hover {
  text-decoration: none !important;
}

.sidebar {
  overflow-y: scroll;
  overflow-x: hidden;
  width: 275px;
  background-color: #E6E6E6;
}

.sidebar.open {
  width: 290px;
  overflow-y: scroll;
  overflow-x: hidden;
  /* Width when open */
  transition: 1s;
}

.sidebar-heading svg {
  display: none;
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.sidebar::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
.sidebar::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 20px;
}

.sidebar.closed {
  width: 290px;
  /* Width when closed */
  transition: 0.2s;
}

.sublist {
  padding-left: 4% !important;
  height: 30% !important;
}

.logo-title {
  font-size: 26px;
  font-weight: 500;
  line-height: 35.46px;
  letter-spacing: 0.01em;
  text-align: left;
  margin-left: -27px;
}

li {
  padding: 5px 20px;
  cursor: pointer;
}

.sidebar-heading {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sidebar-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.sidebar-title img {
  width: 12px;
  height: 12px;
}

li span {
  font-size: 16px;
  font-weight: 600;
  line-height: 19.1px;
  letter-spacing: 0.4px;
  margin-left: 6%;
}

a {
  text-decoration: none;
  padding: 8px;
  border-radius: 8px;
  width: 100%;
  display: block;
}

.menu-button {
  display: flex;
  align-items: center;
  border: none;
  border-radius: 10px;
}

ul {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 85%;
  width: 290px;
}

.logo {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
}

.sidebar-menu {
  list-style-type: none;
  padding-left: 0px;
  margin-top: 5%;
}

.submenu {
  list-style-type: none;
  padding-left: 20px;
  margin-top: 10px;
}

.active {
  background-color: #e6f8f4;
  color: #77d7c2;
}

.sub-active {
  color: #77d7c2;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4%;
}

.sidebar-toggle {
  width: 20px;
  height: 25px;
  margin-right: 1px;
  z-index: 1000;
  margin-top: -22px;
}

.sidebar-toggle img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 650px) {
  li {
    padding: 12px 10px;
  }

  .sidebar.closed {
    width: 60px;
  }
}

.sidebar {
  transition: transform 0.3s ease;
}

.sidebar.hidden {
  transform: translateX(-100%);
  transition: 0.2s ease !important;
}

.sidebar.open {
  transform: translateX(0);
  transition: 0.2s ease !important;
}

.sidebar-toggle-button {
  display: none;
}

/* Mobile Styles */
@media (max-width: 900px) {
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    /* Adjust width as needed */
    transform: translateX(-100%);
    right: 100px !important;
    z-index: 1000;
    transition: 0.2s;
  }

  .sidebar-heading svg {
    display: flex;
  }
  
  .sidebar-heading {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .logo-title {
    font-size: 19.5px;
  }

  .sidebar.open {
    transform: translateX(0);
  }

  .sidebar.closed {
    width: 80px;
    transition: 0.2s;
  }

  .sidebar-toggle-button {
    display: inline-block;
    cursor: pointer;

  }
}

@media (min-width:1024px) and (max-width:1421px) {

  .sidebar {
    overflow-y: unset !important;
    overflow-x: unset !important;
    width: 284px !important;
    background-color: #E6E6E6;
  }
  }