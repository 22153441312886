.addlead {
  padding: 0% 2% 4% 4%;
  font-family: "Nunito", sans-serif;
}

.lead-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 5%;
}

.lead-title span {
  font-size: 22px;
  font-weight: 500;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}

.body {
  background-color: rgba(247, 255, 250, 1);
}

label {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 1%;
  color: rgba(3, 2, 41, 1);
}

.addleadinput,
textarea,
.file-upload,
.file-uploaded,
.add-item {
  border-radius: 7px;
  background: rgba(247, 247, 248, 1);
  border: 0.45px solid rgba(14, 140, 67, 1);
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2) inset;
  height: 52px;
  width: 94%;
  padding: 0px 4%;
}

select {
  border-radius: 7px;
  background: rgba(247, 247, 248, 1);
  border: 0.45px solid rgba(14, 140, 67, 1);
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2) inset;
  height: 52px;
  width: 88%;
  padding: 0px 4%;
}

.add-more-btn {
  margin-top: 10px;
  cursor: pointer;
}

textarea,
.file-uploaded {
  height: 100px;
  padding: 2% 4%;
}

.add-item {
  height: auto;
  width: 96%;
  padding-top: 6%;
  padding-bottom: 2%;
}

.lead-form {
  margin: 20px 0px;
  display: flex;
  flex-wrap: wrap;
}

.lead-form .leadformfields {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 10px 0;
}

.newlead-btn {
  width: 220px;
  height: 53.04px;
  top: 858px;
  left: 773px;
  padding: 13.02px 18.23px 13.02px 18.23px;
  gap: 5.21px;
  border-radius: 10.42px 10.42px 10.42px 10.42px;
  opacity: 0px;
  background: rgb(146, 145, 172);
  color: #ffff;
  font-size: 18.23px;
  font-weight: 600;
  line-height: 26.04px;
  letter-spacing: 0.005em;
  text-align: center;
}

.btnleadnew {
  padding-top: 2% !important;
  justify-content: center !important;
  display: flex !important;
}

.rs-picker {
  height: 52px !important;
}

.rs-input-group.rs-input-group-inside {
  width: 94% !important;
  height: 100%;
  border-radius: 7px;
  background: rgba(247, 247, 248, 1);
  border: 0.45px solid rgba(14, 140, 67, 1);
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2) inset;
}

.rs-input {
  border-radius: 7px;
  background: rgba(247, 247, 248, 1);
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2) inset;
}

.rs-picker-popup .rs-calendar {
  min-width: 334px !important;
}

.rs-calendar-month-dropdown-list {
  min-width: 250px !important;
}

.close-icon {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {

  .addleadinput,
  select,
  textarea {
    width: 100% !important;
  }

  .lead-form {
    width: 100% !important;
  }

  .leadformfields {
    width: 100% !important;
  }

  .rs-input-group.rs-input-group-inside {
    width: 100% !important;
  }

  .mobile-item {
    margin-top: 10px;
  }

  .close-icon svg {
    float: none !important;
    margin-bottom: 15px;
  }

  .close-icon {
    display: flex;
    justify-content: flex-end;
  }

  .rs-picker-popup .rs-calendar {
    min-width: 240px !important;
  }
  
  .rs-calendar-month-dropdown-list {
    min-width: 240px !important;
  }

  .file-upload,
  .file-uploaded {
    width: 100%;
  }

  .rs-picker {
    width: 90vw !important;
    height: auto !important;
  }

  .table {
    width: 50% !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lead-form {
    display: grid !important;
  }

  .lead-form div {
    width: 100% !important;
  }

  .addleadinput,
  select,
  textarea {
    width: 100% !important;
  }
}