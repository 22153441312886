.bgcol {
  background-color: #2c3192;
  height: 100vh;
}

.forgotdiv {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.place {
  font-size: 18px;
  font-weight: 500;
}

.forgot-password {
  text-decoration: underline;
}

.overflow {
  overflow: hidden !important;
}

.alignitemcenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.paddingleft {
  padding-left: 10%;
  padding-right: 10%;
}

.subbutton {
  background-color: #696992 !important;
  border: none !important;
  border-radius: 8px !important;
  font-size: 18px !important;
  color: #ffffff !important;
  transition: background-color 0.3s, color 0.3s !important;
  display: inline-block !important;
}

.subbutton:active {
  background-color: #3e8e41 !important;
}

.imgbg {
  width: 100%;
  margin-top: -13%;
  height: 900px;
}

.imgcol {
  padding: 0% !important;
}

.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: white;
  font-size: 15px;
  margin-top: -37%;
}

.footer-logo {
  width: 80px;
  margin-bottom: 10px;
}

.rights-text,
.developer-text {
  margin: 0;
}

.powertext {
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
}

.bg{
  background: #e6f0f7 !important;
    border: none !important;
    border-radius: 8px !important;
}
.form-login{
  background: #e6f0f7 !important;
  height: 45px;
  padding: 14px;
  border-radius: 8px;
  border: none;
  color: black;
  display: flex;
  justify-content: space-between;
}
.form-login.form-control {
  border: none !important;
  background: #e6f0f7 !important;
  outline: none !important;
}
.form-login input {
  /* border: none !important; */
  outline: none !important;
  /* font-size: 18px; */
  /* padding-bottom: 4px; */
  /* width: 100%; */
  /* height: 22px */
}
.form-control:focus{
  box-shadow: none !important;
}


.form-login input {
  color: #000000 !important;
}

input[type=password], input[type=text] {
    font-size: 15px;
    /* padding: .5rem; */
    color: #000000;
}