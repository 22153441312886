body {
  font-family: "Nunito", sans-serif;
}

.layout {
  display: flex;
  height: 100vh;
  width: 100vw;
}

.main-content {
  width: 80vw;
}

.sidebar {
  width: 20vw;
}

.custom-table {
  width: 80vw;
}

@media (max-width: 768px) { 
  .main-content {
    width: 100vw;
  }
  .newlead-btn {
    width: 92px !important;
    height: 49.04px !important;
  }
  .custom-table {
    width: 100vw;
  }
}

@media (min-width:820px) and (max-width:1180px) {
  .main-content {
    width: 100vw !important;
  }

  .custom-table {
    width: 100vw !important;
  }

}
