@media only screen and (min-width: 320px) and (max-width: 767px) {
  h5 {
    height: 35px !important;
  }

  .mobile-button {
    justify-content: space-between !important;
  }

  .mobile-button button:first-child {
    margin-left: 4%;
  }

  .rs-modal-content {
    width: 98vw !important;
  }

  .custom-table .form-control {
    width: 90% !important;
  }
}

/* .table-responsive {
  height: 48vh;
  margin-top: 10px;
  margin-bottom: 10px;
} */