body {
  font-family: "Nunito", sans-serif;
  background-color: rgba(247, 255, 250, 1);
}

.dashboard {
  background-color: rgba(247, 255, 250, 1);
  font-family: "Nunito", sans-serif;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  gap: 20px;
  padding: 40px 0px 0px;
  margin: 0px 4%;
}

.card-title {
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.18px;
  letter-spacing: 0.3px;
  text-align: left;
  color: rgba(32, 34, 36, 0.7);
  ;
}

.card-info {
  font-size: 24.6px;
  font-weight: 700;
  line-height: 33.56px;
  letter-spacing: 0.8px;
  text-align: left;
  color: rgba(32, 34, 36, 1);
  margin-bottom: 0px;
  margin-top: 7%;
}

.card-details {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 92%;
}

.card-section {
  width: 23%;
  box-shadow: 5.27px 5.27px 47.45px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  background-color: #fff;
  margin-bottom: 35px;
  /* height: 120px; */
  position: relative;
  background-color: rgba(255, 255, 255, 1);
  border: none;
  cursor: pointer;
}

.card-section h2 {
  margin-top: 0;
}

.card-section span {
  font-family: "Nunito", sans-serif;
}

.card-details img {
  width: 40px;
}

@media (max-width: 768px) {
  .card-section {
    width: 100%;
  }
}
@media (max-width: 1000px) {
  .card-section {
      width: 100%;
  }
}

